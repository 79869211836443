import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaGooglePlusG } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import preview from "../images/preview.png";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { Translate } from "react-translated";
import support from "../images/support.jpg";

const Footer = () => {
  const theme = useTheme();
  const isSmallerThanMedium = useMediaQuery(theme.breakpoints.down("md"));

  if (isSmallerThanMedium) {
    return (
      <div className="bg-gradient-to-t from-[#d5e3ff] to-[#ffffff] font-light mt-[8rem]">
        <Grid container spacing={5} className="xs:px-[1rem] px-[2rem]">
          <Grid item xs={12} className="">
            <img src={preview} alt="" className="h-[60px]" />
            <p className="text-[1rem] mt-4">
              <Translate text="We are commited to ensuring a seamless Booking and Hosting" />
            </p>
          </Grid>
          <Grid item xs={12}>
            <h3 className="text-3xl font-bold">
              <Translate text="For hosts" />
            </h3>
            <p className="text-[1rem]">
              <Translate text="How to start renting out a property" />
            </p>
            <p className="text-[1rem] ">
              <Translate text="Place an Ad" />
            </p>
            <p className="text-[1rem]">
              <Translate text="Help" />
            </p>
          </Grid>
          <Grid item xs={12}>
            <h3 className="text-3xl font-bold">
              <Translate text="For Guests" />
            </h3>
            <p className="text-[1rem]">
              <Translate text="How to book accomodation" />
            </p>
            <p className="text-[1rem]">
              <Translate text="Guarantees" />
            </p>
            <p className="text-[1rem]">
              <Translate text="Guest Reviews" />
            </p>
            <p className="text-[1rem]">
              <Translate text="Cashback 10%" />
            </p>
          </Grid>
          <Grid item xs={12}>
            <p className="text-[1rem]">
              <Translate text="Personal Area" />
            </p>
            <Link to={"support"}>
              <p className="text-[1rem]">
                {" "}
                <Translate text="Support" />
              </p>
            </Link>

            <p className="text-[1rem]">
              <Translate text="+7 (904) 663-69-63" />
            </p>
          </Grid>
        </Grid>

        <div className="pt-[3rem] xs:px-[1rem] pb-[5rem] px-[6rem] border-t border-gray-300 mt-5">
          <div className="flex xs:flex-col sm:flex-col flex-row md:items-center justify-between md:gap-5 sm:gap-10 xs:gap-10  w-full mx-auto text-[#646466]">
            <div>
              <p className="text-[1rem]">
                <Translate text="All right Reserved" /> @Miradom.{" "}
                {new Date().getFullYear()}
              </p>
            </div>
            <div className="">
              <div className="flex gap-5 items-center text-primary text-[2.2rem] lg:text-[1.5rem] md:text-[1.3rem] sm:text-[1rem] xs:text-[1.3rem]">
                <FaFacebookF />
                <FaTwitter />
                <FaYoutube />
                <FaGooglePlusG />
                <FaInstagram />
              </div>
            </div>
          </div>
          {/* Enhanced Banner */}
          <div className="mt-6 flex flex-col-reverse items-center bg-gradient-to-r from-[#1e3a8a] to-[#3b82f6] text-white py-6 px-8 rounded-lg shadow-lg">
            <img
              src={support}
              alt="Support Logo"
              className="w-40 h-20 mt-4  rounded-lg"
            />
            <p className="text-lg font-semibold tracking-wide leading-relaxed text-center md:text-left">
              Проект создан при поддержке Федерального государственного
              бюджетного учреждения Фонд содействия развитию малых форм
              предприятий в научно-технической сфере в рамках программы
              "Студенческий стартап" федерального проекта "Платформа
              университетского технологического предпринимательства"
            </p>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="bg-gradient-to-t from-[#d5e3ff] to-[#ffffff] mt-[8rem] font-light">
        <Grid container spacing={5} className="xs:px-[1rem] px-[6rem]">
          <Grid item md={3} className="flex items-end">
            <img src={preview} alt="" className="h-[60px]" />
          </Grid>
          <Grid item md={3}>
            <h3 className="text-3xl font-bold h-[90px] inline-flex items-end">
              <Translate text="For hosts" />
            </h3>
          </Grid>
          <Grid item md={3}>
            <h3 className="text-3xl font-bold h-[90px] inline-flex items-end">
              <Translate text="For Guests" />
            </h3>
          </Grid>
          <Grid item md={3}></Grid>
          <Grid item md={3}>
            <p className="text-[1rem]">
              <Translate text="We are commited to ensuring a seamless Booking and Hosting" />
            </p>
          </Grid>
          <Grid item md={3}>
            <p className="text-[1rem]">
              <Translate text="How to start renting out a property" />
            </p>
            <p className="text-[1rem] ">
              {" "}
              <Translate text="Place an Ad" />
            </p>
            <p className="text-[1rem]">
              {" "}
              <Translate text="Help" />
            </p>
          </Grid>

          <Grid item md={3}>
            <p className="text-[1rem]">
              {" "}
              "<Translate text="invite a friend" />"
            </p>
            <p className="text-[1rem]">
              {" "}
              <Translate text="How to book accomodation" />
            </p>
            <p className="text-[1rem]">
              {" "}
              <Translate text="Guarantees" />
            </p>
            <p className="text-[1rem]">
              {" "}
              <Translate text="Guest Reviews" />
            </p>
          </Grid>
          <Grid item md={3}>
            <p className="text-[1rem]">
              <Translate text="Personal Area" />
            </p>
            <Link to={"support"}>
              <p className="text-[1rem]">
                <Translate text="Contact" />
              </p>
            </Link>

            <p className="text-[1rem]">
              <Translate text="+7 (904) 663-69-63" />
            </p>
          </Grid>
        </Grid>

        <div className="pt-[3rem] xs:px-[1rem] pb-[5rem] px-[6rem] border-t border-gray-300 mt-5">
          <div className="flex items-center justify-between md:gap-5 sm:gap-10 xs:gap-10  w-full mx-auto text-[#646466]">
            <div>
              <p className="text-[1rem]">
                <Translate text="All right Reserved" /> @Miradom.{" "}
                {new Date().getFullYear()}
              </p>
            </div>
            <div className="">
              <div className="flex gap-5 items-center text-primary text-[2.2rem] lg:text-[1.5rem] md:text-[1.3rem] sm:text-[1rem] xs:text-[1.3rem]">
                <FaFacebookF />
                <FaTwitter />
                <FaYoutube />
                <FaGooglePlusG />
                <FaInstagram />
              </div>
            </div>
          </div>

          {/* Enhanced Banner */}

          <div className="mt-6 flex items-center bg-gradient-to-r from-[#1e3a8a] to-[#3b82f6] text-white py-6 px-8 rounded-lg shadow-lg">
            <img
              src={support}
              alt="Support Logo"
              className="w-34 h-20 mr-4 rounded-lg"
            />
            <p className="text-lg font-semibold tracking-wide leading-relaxed">
              Проект создан при поддержке Федерального государственного
              бюджетного учреждения Фонд содействия развитию малых форм
              предприятий в научно-технической сфере в рамках программы
              "Студенческий стартап" федерального проекта "Платформа
              университетского технологического предпринимательства"
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
